.wallet-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0px 0px 5px;
  padding: 15px;
  border-radius: 8px;
  height: 30px;
  width: calc(100%-20px);
}

.wallet-info {
  display: flex;
  align-items: center;
  flex: 1; /* Take up remaining space */
}

.wallet-name {
  font-weight: bold;
  margin-right: 10px;
}

.wallet-balance {
  color: red; /* Red color for balance */
}

.radio-input {
  margin-right: 10px; /* Adjust spacing between radio button and wallet info */
}
