body{
    background-color: var(--light-app-color);
}
.content-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100wh;
}
.updateIcon {
    width: 150px;
    height: 150px;
}