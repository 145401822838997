.carousel {
    height: 160px;
    width: calc(100% - 20px);
    margin: 0px 10px 10px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.carousel-inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.carousel-item {
    min-width: 100%;
    height: 160px;
}

.carousel-item img {
    width: 100%;
    height: 100% !important;
}
