.install_page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85vh;
}

.install_button{
    height: 40px;
    width: 120px;
}