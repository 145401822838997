.transaction-summary-container {
    width: calc(100%-10px);
    margin: 0px 10px 10px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}
.transaction-summary {
    display: flex;
    flex-wrap: nowrap;
}
body::-webkit-scrollbar {
    display: none;
}
.transaction-summary-container::-webkit-scrollbar {
    display: none;
}
.transaction-card {
    color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    min-width: 121px;
    height: 55px;
    text-align: center;
    margin-right: 5px;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
}

.transaction-card p {
    margin: 5px 0;
}