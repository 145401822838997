.activity-header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 55px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: var(--app-color);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 5px 0px;
    overflow-x: auto;
    gap: 10px;
    color: #ffffff;
}