.table_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3px 10px 0px;
}

.margin_card {
  display: flex;
  justify-content: space-between; /* Ensure space between content */
  align-items: center; /* Center items vertically */
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: calc(100% - 20px);
  margin: 2px 0px 2px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  gap: 10px;
}

.biller_info {
  display: flex;
  flex-direction: column;
}

.biller_name {
  font-size: 14px;
  font-weight: bold;
}

.biller_mode {
  font-size: 12px;
  color: gray;
}

.margin {
  text-wrap: nowrap;
  font-weight: bold;
  margin-left: auto; 
  font-size: 11px;
  padding: 5px;
  background-color: #eeeaea;
  border-radius: 5px;
  color: black;
  height: 20px;
  min-width: 45px;
  align-content: center;
  text-align: center;
}

.company-logo {
  height: 40px;
  width: 40px;
}

.loading {
  font-size: 16px;
  font-weight: bold;
  color: gray;
}

/* Responsive design for mobile devices */
@media (max-width: 600px) {
  .custom-table {
    font-size: 12px;
  }
}
