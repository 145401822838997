* {
    margin: 0px;
    padding: 0px;
}

body {
    background-color: #E5F8F1;
}

body::-webkit-scrollbar {
    display: none;
}

nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 65px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: var(--app-color);
    display: flex;
    overflow-x: auto;
    z-index: 99;
}

.nav_link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    font-family: sans-serif;
    font-size: 13px;
    text-decoration: none;
    color: #e2d7d7;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.1s ease-in-out;
}

.header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 55px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: var(--app-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    overflow-x: auto;
    z-index: 99;
}

.loading-btn {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #e3ecf1;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    padding: 6px;
    animation: spin 1s linear infinite;
    margin: 5px auto;
}

.loading-transaction {
    border: 6px solid rgba(0, 0, 0, 0.1);
    border-left-color: #e3ecf1;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    padding: 6px;
    animation: spin 1s linear infinite;
    margin: 15px auto;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}



.active {
    color: #FFFFFF;
}

.main-container {
    margin: 55px 0px 75px;
}

.page-container {
    margin: 65px 0px 5px;
}

.bg-light-app {
    height: 100%;
    width: 100%;
    background-color: var(--light-app-color);
}

.bg-app {
    background-color: var(--app-color);
}

.form_div {
    padding: 10px;
}

.form-control {
    width: calc(100% - 22px);
    margin: 5px 0px 5px;
    padding: 10px;
    height: 25px;
    border: 1.5px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    outline: none;
}

.form-control-textarea {
    width: calc(100% - 22px);
    margin: 5px 0px 5px;
    padding: 10px;
    border: 1.5px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    outline: none;
}

.form-control-select {
    width: 100%;
    margin: 5px 0px 5px;
    padding: 15px;
    order: 1.5px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    outline: none;
    -webkit-appearance: none;
    appearance: none;
}

select.form-control-select {
    -webkit-appearance: none;
    /* For WebKit browsers */
    appearance: none;
    /* Standard property */
}

/* Option styling - note that styling for option elements can be limited */
.form-control-select option {
    background-color: white;
    /* Ensure option background matches select */
    color: initial;
    /* Reset color */
}

.form-btn {
    width: 100%;
    margin: 5px 0px 5px;
    background-color: var(--app-color);
    color: white;
    border: none;
    border-radius: 5px;
}

.btn-text {
    padding: 5px;
    margin: 10px;
}

.checkbox-label {
    margin: 10px;
    display: flex;
    gap: 5px;
}

.btn {
    background-color: var(--app-color);
    color: white;
    border: none;
    border-radius: 5px;
}

.text-white {
    color: #FFFFFF;
}

.text-app {
    color: var(--app-color);
}

.bg-white {
    background-color: white;
}

.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.flex {
    display: flex;
}

button.false {
    background-color: var(--disabled-app-color);
}

button.true {
    background-color: var(--app-color);
}

.cursor-pointer {
    cursor: pointer;
}

.w-30 {
    width: 30%;
}

.p-10 {
    padding: 10px;
}

.mr-10 {
    margin-right: 10px;
}

.mt-10 {
    margin-top: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

/* Login page */
/* General Styles */
.login-body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: linear-gradient(135deg, #00BB76, #004B2E);
}

/* Container */
.login-container {
    background: #fff;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 40px 30px;
    max-width: 100vw;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 75vh;
}

/* Header */
.login-header-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Centers content vertically within the container */
    align-items: flex-start;
    /* Aligns content to the left horizontally */
    height: 25vh;
    /* Keeps the header occupying 30% of the viewport height */
    margin-bottom: 20px;
    margin-top: 20px;
    padding-left: 20px;
    /* Adds padding for better spacing from the left edge */
}

.login-title {
    color: #fff;
    font-size: 2em;
    margin: 0;
    user-select: none;  /* Standard syntax */
    -webkit-user-select: none;  /* Safari */
    -moz-user-select: none;  /* Firefox */
    -ms-user-select: none; 
}

.login-subtitle {
    color: #fff;
    font-size: 1.5em;
    margin-bottom: 20px;
    user-select: none;  /* Standard syntax */
    -webkit-user-select: none;  /* Safari */
    -moz-user-select: none;  /* Firefox */
    -ms-user-select: none; 
}

/* Form Styles */
.login-form {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.login-label {
    font-weight: bold;
    margin: 10px 0 5px;
}

.login-input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Forgot Password */
.login-forgot-password {
    text-align: right;
    font-size: 0.9em;
    color: #888;
    margin-bottom: 20px;
    text-decoration: none;
}

/* Sign In Button */
.login-signin-btn {
    background: linear-gradient(90deg, #00BB76, #004B2E);
    color: white;
    border: none;
    padding: 15px;
    font-size: 1em;
    border-radius: 25px;
    cursor: pointer;
    margin: 20px 0;
    outline: none;
}

.login-signin-btn-loading{
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #e3ecf1;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    animation: spin 1s linear infinite;
    margin: 0px auto;
}

.login-signin-btn:hover {
    opacity: 0.9;
}

/* Sign Up Text */
.login-signup-text {
    font-size: 0.9em;
}

.login-signup-link {
    color: #B91D73;
    text-decoration: none;
    font-weight: bold;
}

.login-checkbox {
    margin: 10px 0px;
    display: flex;
    align-items: center;
    /* Aligns checkbox and label vertically */
    gap: 8px;
    /* Optional: Adjusts space between checkbox and label */
}

.login-checkbox input {
    vertical-align: middle;
    /* Ensures checkbox aligns with the text */
}

.login-checkbox-text {
    margin: 0;
    font-size: 14px;
    color: #333;
    /* You can adjust the text color if needed */
}



/* Home page */
.home-dashboard {
    height: 100px;
    width: 100%;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: var(--app-color);
    position: fixed;
}

.home-dashboard-space {
    padding-top: 50px;
    padding-bottom: 50px;
}

.home-dashboard-content {
    padding: 15px 10px 0px;
    color: #ffffff;
}

.home-dashboard-divider {
    width: 50px;
    height: 1px;
    color: #eeeeee;
    background-color: #eeeeee;
    margin: 5px 5px 10px;
}

.home-spotlight {
    padding-left: 10px;
    padding-right: 10px;
}

.home-spotlight-container {
    height: 180px;
    width: calc(100% - 0px);
    display: flex;
    overflow-x: auto;
    align-items: center;
}

.home-spotlight-container::-webkit-scrollbar {
    display: none;
}

.home-spotlight-item {
    height: 150px;
    min-width: 100px;
    background-color: white;
    color: black;
    margin: 0px 5px 10px 5px;
    border-radius: 5px;
    padding: 10px 10px 10px 10px;
    text-decoration: none;
}

.home-bg-spotlight-1 {
    background-color: rgb(82, 240, 240);
}

.home-bg-spotlight-2 {
    background-color: rgba(110, 158, 218, 0.5);
}

.home-bg-spotlight-3 {
    background-color: rgba(187, 235, 77, 0.5);
}

.home-bg-spotlight-4 {
    background-color: rgb(208, 171, 230);
}

.home-spotlight-item img {
    height: 50px;
    width: 50px;
}

.home-spotlight-item h5 {
    font-size: 12px;
}

.home-spotlight-item p {
    margin-top: 5px;
    font-size: 10px;
}

.home-header-icon {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

/* Topup page */
.topup-recommended-amount-list {
    margin: 5px 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.topup-recommended-amount {
    background-color: white;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    padding: 5px;
    width: 50px;
    text-align: center;
}

/* mobile prepaid recharge page */
.prepaid-mobile-recharge-tabs-container {
    display: flex;
    overflow-x: scroll;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.prepaid-mobile-recharge-tabs-container::-webkit-scrollbar {
    display: none;
}

.prepaid-mobile-recharge-tab {
    margin-right: 10px;
}

.prepaid-mobile-recharge-tab button {
    background-color: #efecec;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 15px;
    transition: background-color 0.3s ease;
    border-radius: 5px;
}

.prepaid-mobile-recharge-tab button:hover {
    background-color: #d3cfcf;
}

.prepaid-mobile-recharge-tab button.active {
    background-color: var(--app-color);
}

.prepaid-mobile-recharge-tab-content-wrapper {
    overflow-y: auto;
    height: calc(100vh - 50px);
}

.prepaid-mobile-recharge-tabs-all {
    margin: 10px;
}

.prepaid-mobile-recharge-plan-row {
    padding: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    border-radius: 8px;
    padding: 10px;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}




/* Service Components */
.service-card-item {
    width: calc(100% - 40px);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: white;
    margin: 0px 10px 10px;
    border-radius: 5px;
    padding: 10px;
}

.service-card-item-header {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    gap: 5px;
}

.service-card-header-bar {
    height: 13px;
    width: 3px;
    background-color: var(--app-color);
    color: var(--app-color);
}

.service-item-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: flex-start;
    /* Align items to the start */
    justify-content: space-evenly;
    margin: 5px 0px 5px;
}

.service-item-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px;
    text-decoration: none;
    color: #444444;
    width: 100px;
    /* Adjust width as needed */
}

.service-item-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    /* Reduced padding */
    border-radius: 10px;
    margin: 0px 0px 1px;
    /* Reduce the bottom margin */
    height: 40px;
    /* Reduced height for consistency */
}

.service-item-card p {
    margin: 0;
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    margin-top: 1px;
    /* Reduce the top margin */
    height: 28px;
    /* Reduced height to ensure text consistency */
    line-height: 1.2;
    /* Adjust line-height for text wrapping */
}


/* Error Components */
.error-page{
    background-color: #f0f6ff;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}
.error-back-button{
    position: relative;
    display: flex;
    align-items: center;
    top: 20px;
    left: 20px;
    gap: 10px;
}
.error-content{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.error-refresh-btn{
    color: white;
    background-color: #0075ff;
    margin: 30px 10px;
    padding: 8px 15px;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 5px;
    border: none;
    border-radius: 5px;
}