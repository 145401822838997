.status_div {
    height: 200px;
    width: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    border-radius: 0px 0px 10px 10px;
    z-index: 1000; /* Ensure it's above other elements */
}

.backbutton {
    position: absolute;
    top: 15px;
    left: 15px;
    color: white;
    z-index: 1000; /* Ensure it's above other elements */
}

.center_container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    top: -20px;
}

.center_container p {
    color: white;
    font-size: 17px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
}

.status_icon {
    height: 70px;
    width: 70px;
    margin-bottom: 10px;
    z-index: 1000; /* Ensure it's above other elements */
}

.transaction_heading {
    position: relative;
    top: -60px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    background-color: white;
    color: black;
}

.order_details {
    border-radius: 10px;
    margin: 280px 10px 5px;
    padding: 20px;
    background-color: white;
}

.order_details p {
    word-spacing: 5px;
    padding: 2px 0px 2px;
}

.share_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 7px;
}

.order_dispute {
    display: flex;
    align-items: center;
    border-radius: 10px;
    margin: 0px 10px 5px;
    padding: 20px;
    background-color: white;
}

.order_dispute p {
    display: flex;
    align-items: center;
    margin: 0;
}

.order_dispute p svg {
    margin-right: 8px; /* Adjust the spacing between the icon and the text */
}

.whatsapp_icon {
    color: #25D366;
}

.order_rewards {
    display: flex;
    align-items: center;
    border-radius: 10px;
    margin: 0px 10px 5px;
    padding: 20px;
    background-color: white;
    justify-content: space-between;
}

.order_rewards p {
    display: flex;
    align-items: center;
    margin: 0;
}

.order_rewards p svg {
    margin-right: 8px; /* Adjust the spacing between the icon and the text */
    color: red;
}

.loading_center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.error_center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}
