.card-profile {
    padding: 12px;
    background-color: var(--light-app-color);
    color: black;
    text-decoration: none;
    align-items: center;
    margin: 0px 5px 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}