.statement-card {
  margin: 0px 5px 10px;
  padding: 0px 0px 10px;
  border-radius: 5px;
}

.transaction-item {
  display: flex;
  align-items: center;
  margin: 5px 5px 5px;
  padding: 12px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.icon-container {
  flex: 0 0 auto;
  margin-right: 10px;
}

.icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.details {
  flex: 1;
}

.title {
  margin: 0;
  font-size: 14px;
}

.description {
  margin: 5px 0;
  color: #666;
  font-size: 11px;
}

.amount {
  flex: 0 0 auto;
  font-size: 12px;
  padding: 5px;
  background-color: #eeeaea;
  border-radius: 5px;
  color: black;
  height: 20px;
  width: 55px;
  align-content: center;
  text-align: center;
}

.status-success {
  margin-left: 5px;
  padding: 3px;
  color: white;
  border-radius: 3px;
  background-color: #14A44D;
}

.status-failed {
  margin-left: 5px;
  padding: 3px;
  color: white;
  border-radius: 3px;
  background-color: #DC4C64;
}

.status-pending {
  margin-left: 5px;
  padding: 3px;
  color: white;
  border-radius: 3px;
  background-color: #E4A11B;
}
.loading {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

.loading-inside-btn {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #e3ecf1;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
