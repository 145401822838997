
.company-card {
    display: flex;
    flex-direction: row;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: calc(100%-20px);
    margin: 5px 10px 5px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    gap: 10px;
    align-items: center;
}
.company-logo {
    height: 40px;
    width: 40px;
}