.home-balance-card {
    width: calc(100% - 20px);
    height: 75px;
    background-color: var(--app-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0px 5px 0px;
    overflow-x: auto;
    margin: 10px 10px 10px;
    border-radius: 5px;
    color: #FFFFFF;
}

.home-balance-left {
    padding: 12px;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.home-balance-left-button {
    background-color: #eeeeee;
    color: black;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    padding: 10px;
    gap: 6px;
}

.two-part {
    width: calc(100% - 10px);
    display: flex;
    margin: 0px 5px 0px;
    flex-direction: row;
}

